<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" lg="6" md="8" sm="10" class="mx-auto">
          <div class="my-5 my-lg-16 rounded-lg pa-5 border overflow-hidden shadow-light">

            <h1 class="text-uppercase lh-1 mb-4">
              <span class="display-1 primary--text fw-900">
                {{ authSettings.customer_login_with == 'email' ? $t('update_email') : $t('update_phone') }}
              </span>
            </h1>

            <v-form ref="loginForm" lazy-validation @submit.prevent="verifyAccount()">
              <div
                v-if="
                  authSettings.customer_login_with == 'email' ||
                    (authSettings.customer_login_with == 'email_phone' &&
                      authSettings.customer_otp_with == 'email')
                "
                class="mb-4"
              >
                <div class="mb-1 fs-13 fw-500">{{ $t('email') }}</div>
                <v-text-field
                  v-model="form.email"
                  :placeholder="$t('email_address')"
                  type="email"
                  :error-messages="emailErrors"
                  hide-details="auto"
                  required
                  outlined
                ></v-text-field>
              </div>
              <div
                v-if="
                  authSettings.customer_login_with == 'phone' ||
                    (authSettings.customer_login_with == 'email_phone' &&
                      authSettings.customer_otp_with == 'phone')
                "
                class="mb-4"
              >
                <div class="mb-1 fs-13 fw-500">
                  {{ $t('phone_number') }}
                </div>
                <vue-tel-input
                  v-model="form.phone"
                  v-bind="mobileInputProps"
                  :preferred-countries="availableCountries"
                  :auto-format="false"
                  @country-changed="onCountryChanged"
                  @validate="phoneValidate"
                >
                  <template #arrow-icon>
                    <span style="margin: 0 3px;">{{ dialCode ? `+${dialCode}` : '' }}</span>
                    <span class="vti__dropdown-arrow">&nbsp;▼</span>
                  </template>
                </vue-tel-input>
                <div v-if="$v.form.phone.$error" class="v-text-field__details mt-2 pl-3">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{ $t('this_field_is_required') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!$v.form.phone.$error && form.showInvalidPhone"
                  class="v-text-field__details mt-2 pl-3"
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{ $t('phone_number_must_be_valid') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <div class="mb-1 fs-13 fw-500">{{ $t('code') }}</div>
                <v-otp-input
                  v-model="form.code"
                  length="5"
                  type="number"
                  :error-messages="codeErrors"
                  hide-details="auto"
                  :disabled="loading"
                  required
                ></v-otp-input>
              </div>
              <v-btn
                x-large
                class="px-12 mb-4"
                elevation="0"
                type="submit"
                color="primary"
                :loading="loading"
                :disabled="loading"
                @click="verifyAccount"
              >
                {{ $t('verify') }}
              </v-btn>
              <v-btn
                x-large
                class="px-12 mb-4 ms-3"
                elevation="0"
                type="button"
                color=""
                :loading="resendLoading"
                :disabled="resendLoading"
                @click="resendCode"
              >
                {{ $t('seller_password_send_code') }}
              </v-btn>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { VueTelInput } from 'vue-tel-input'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data: () => ({
    mobileInputProps: {
      inputOptions: {
        type: 'tel',
        placeholder: 'phone number'
      },
      dropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true
      },
      autoDefaultCountry: false,
      validCharactersOnly: true,
      mode: 'international'
    },
    form: {
      email: '',
      phone: '',
      code: '',
      invalidPhone: true,
      showInvalidPhone: false
    },
    loading: false,
    resendLoading: false,
    source: '',
    // 国家区号
    dialCode: '',

    // 电话号码是否禁止输入
    phoneDisabled: false
  }),

  components: {
    VueTelInput
  },

  validations: {
    form: {
      email:{ required, email },
      phone: { required },
      code: {
        required
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['authSettings']),
    ...mapGetters('app', ['availableCountries', 'h5Url', 'getSellerH5Url']),
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required && errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.email.email && errors.push(this.$i18n.t('this_field_is_required_a_valid_email'))
      return errors
    },
    codeErrors() {
      const errors = []
      if (!this.$v.form.code.$dirty) return errors
      !this.$v.form.code.required && errors.push(this.$i18n.t('this_field_is_required'))
      return errors
    }
  },

  watch: {
    'form.phone'(value) {
      if (String(value).indexOf('0') === 0) {
        this.form.phone = this.form.phone.substring(1)
      }
    }
  },

  methods: {
    ...mapActions('auth', {
      actionLogin: 'login'
    }),
    ...mapMutations('auth', ['updateChatWindow', 'showLoginDialog', 'setAuthSettingsVerify']),
    ...mapActions('app', ['fetchProductQuerries']),
    ...mapActions('wishlist', ['fetchWislistProducts']),
    ...mapActions('cart', ['fetchCartProducts']),

    // 电话区号改变
    onCountryChanged({ dialCode }) {
      this.dialCode = dialCode

      if (this.form.phone.indexOf(dialCode) === 1) {
        this.form.phone = this.form.phone.replace(`+${dialCode}`, '')

        this.phoneDisabled = this.source === 'login' || this.source === 'register' || this.source === 'register_seller'
      }
    },

    phoneValidate(phone) {
      this.form.invalidPhone = !phone.valid
      if (phone.valid) this.form.showInvalidPhone = false
    },
    async verifyAccount() {
      this.$v.form.$touch()
      if (
        this.$v.form.code.$error
        || (this.authSettings.customer_login_with == 'email' && this.$v.form.email.$error) 
        || (this.authSettings.customer_login_with == 'phone' && this.$v.form.phone.$error) 
      ) {
        return
      }
      
      if (
        (this.authSettings.customer_login_with == 'phone' ||
          (this.authSettings.customer_login_with == 'email_phone' &&
            this.authSettings.customer_otp_with == 'phone')) &&
        this.form.invalidPhone
      ) {
        this.form.showInvalidPhone = true
        return
      }

      // 如果是电话号码注册，则带上区号
      const params = { ...this.form }
      if (this.authSettings.customer_login_with == 'phone') {
        this.form.phone = this.form.phone.replace(/\s/g, '')
        params.phone = `+${this.dialCode}${this.form.phone}`
        params.email = ''
      } else {
        params.phone = ''
      }

      this.loading = true

      const methods = this.authSettings.customer_login_with == 'email' ? 'user/info/update-email' : 'user/info/update-phone'

      const res = await this.call_api('post', methods , params)
      if (res.data.result) {
        this.$router.push({ name: 'DashBoard' })
      } else {
        this.snack({
          message: res.data.message,
          color: 'red'
        })
      }
      this.loading = false
    },
    async resendCode() {
      this.authSettings.customer_login_with == 'email' && this.$v.form.email.$touch()
      this.authSettings.customer_login_with == 'phone' && this.$v.form.phone.$touch()

      if (
        (this.authSettings.customer_login_with == 'email' && this.$v.form.email.$error)
        || (this.authSettings.customer_login_with == 'phone' && this.$v.form.phone.$error)
        ) {
        return
      }

      if (
        (this.authSettings.customer_login_with == 'phone' ||
          (this.authSettings.customer_login_with == 'email_phone' &&
            this.authSettings.customer_otp_with == 'phone')) &&
        this.form.invalidPhone
      ) {
        this.form.showInvalidPhone = true
        return
      }

      // 如果是电话号码注册，则带上区号
      const params = { ...this.form }
      if (this.authSettings.customer_login_with == 'phone') {
        this.form.phone = this.form.phone.replace(/\s/g, '')
        params.phone = `+${this.dialCode}${this.form.phone}`
        params.email = ''
      } else {
        params.phone = ''
      }

      const requestMethods = this.authSettings.customer_login_with == 'email' ? 'user/info/send-update-email-verify-code' : 'user/info/send-update-phone-verify-code'

      this.resendLoading = true
      const res = await this.call_api('post', requestMethods , params)

      if (res.data.success) {
        this.snack({
          message: res.data.message
        })
      } else {
        this.snack({
          message: res.data.message,
          color: 'red'
        })
      }
      this.resendLoading = false
    }
  },

  created() {
    if (this.$route.params.email) {
      this.setAuthSettingsVerify('email')
    }
    if (this.$route.params.phone) {
      this.setAuthSettingsVerify('phone')
    }
  }
}
</script>
